import {
  OPEN_MODAL,
  TRACK_INTERACTION,
  triggerEvent,
} from '@fec/assets/js/utils/event';
import { STOP_ICON_CLASS } from '@fec/frontend/modules/content/medium/medium';

export const PLACEHOLDER_SIZE = '#JS-PLACEHOLDER-SIZE#';
export const PLACEHOLDER_POS = '#JS-PLACEHOLDER-POS#';

const TEASER_SIZES_WITH_FLYOUT = ['size-audiothek', 'size-square'];

export const PLAYING_CLASS = 'teaser-ng--active-media js-teaser-playing';

const PLAYING_CLASSES = PLAYING_CLASS.split(' ');
const STOP_ICON_CLASSES = STOP_ICON_CLASS.split(' ');

export const isTeaserPlaying = (element) =>
  element.classList.contains('js-teaser-playing');

export const markTeaserAsPlaying = (element) => {
  element.classList.add(...PLAYING_CLASSES);
  element.querySelector('.play-icon').classList.add(...STOP_ICON_CLASSES);
};

export const markTeaserAsNotPlaying = (element) => {
  element.classList.remove(...PLAYING_CLASSES);
  element.querySelector('.play-icon').classList.remove(...STOP_ICON_CLASSES);
};

export const GET_TEASER_SIZE_FROM_CSS = (element) =>
  window
    .getComputedStyle(element, '::before')
    .getPropertyValue('content')
    .replace(/"/g, '')
    .replace('type-', 'size-');

export function init() {
  // Problem: We only know on click, if a flyout has to be opened for a teaser.
  // Solution: Always render out the necessary data, open flyout via JS on click if conditions are met
  document.addEventListener('click', (event) => {
    const teaserElement = event.target.closest('.js-teaser-ng');

    if (teaserElement) {
      const teaserType = GET_TEASER_SIZE_FROM_CSS(teaserElement);
      const modalJSON = teaserElement?.dataset?.ngModal;

      if (TEASER_SIZES_WITH_FLYOUT.includes(teaserType) && modalJSON) {
        const { modalId, eventTrack } = JSON.parse(modalJSON);

        triggerEvent(OPEN_MODAL, {
          modalId: modalId,
          caller: teaserElement,
        });

        event.preventDefault();
        event.stopPropagation();

        if (eventTrack) {
          triggerEvent(TRACK_INTERACTION, eventTrack);
        }
      }
    }
  });
}
