let config = null;

// singleton pattern: multiple client modules may import getConfig, DOM is accessed only once
export function getConfig() {
  if (config == null) {
    let $config = $('#config__js');
    if ($config.length) {
      config = {};
      config['CONTENT_CLASS'] =
        $('meta[name="srf.content_class"]').prop('content') || '';
      config['BUSINESS_UNIT'] = $config.data('business_unit') || '';
      config['ENVIRONMENT'] = $config.data('environment') || '';
      config['REPRESENTATION'] = $config.data('representation') || '';
      config['SEO_URL'] = $config.data('seo-url') || '';
      config['SPA_PLAYER_JS'] = $config.data('spa_player_js') || '';
      config['LETTERBOX_PLAYER_IL_HOST'] =
        $config.data('letterbox_player_il_host') || '';
      config['ARTICLE_FEATURES'] = $config.data('articleFeatures') || '';
      config['ANALYTICS_ENABLED'] = $config.data('analytics-enabled') || '';
      config['ANALYTICS_WEBTREKK_VALUE_OBJECT'] =
        $config.data('analytics-webtrekk-value-object') || null;
      config['ANALYTICS_UDP_VALUE_OBJECT'] =
        $config.data('analytics-udp-value-object') || null;
      config['ANALYTICS_WEBTREKK_SURVEY_GIZMO_VALUE_OBJECT'] =
        $config.data('analytics-webtrekk-survey-gizmo-value-object') || null;
      config['ANALYTICS_SURVEY_GIZMO_ENABLED'] =
        $config.data('survey-gizmo-enabled') || false;
      config['ANALYTICS_SURVEY_GIZMO_BEACON'] =
        $config.data('analytics-survey-gizmo-beacon') || '';
      config['KAMELEOON_ENABLED'] = $config.data('kameleoon-enabled') || false;
      config['WEBPUSH_STYLE_PROMPT_ENABLED'] =
        $config.data('webpush-style-prompt-enabled') || false;
      config['ANALYTICS_SWISS_MEDIA_DATA_HUB'] =
        $config.data('swiss-media-data-hub') || '';
      config['LOKALPROGNOSE_LP_URL'] =
        $config.data('meteo-lokalprognose-url') || '';
      config['WEBPUSH_SUBSCRIPTION_LIST'] =
        $config.data('webpush-subscription-list') || null;
      config['ANALYTICS_UDP_ENDPOINTS'] = $config.data('udp-endpoints') || null;
      config['CLICKJACKING_ENABLED'] = $config.data('clickjacking') || false;
      config['ROKKA_BASE_URL'] = $config.data('rokka-url') || false;
      config['IL_IMAGE_SERVICE_BASE_URL'] =
        $config.data('il-image-service-url') || false;
    }
  }
  return config;
}
